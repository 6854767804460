.about-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.about-content-container {
  width: 60%;
}

.about-scroll-container {
  overflow: auto;
  width: 70%;
  height: 70vh;
  background: white;
  padding: 20px;
  /* border-radius: 30px 0px 0px 30px; */
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
}

.about-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: justify;

  margin-top: 7vh;

  font-family: "Public Sans";
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  color: var(--primary-color);
}

.about-scroll-container::-webkit-scrollbar {
  width: 0.35rem;
  background-color: #edeaf7;
}

.about-scroll-container::-webkit-scrollbar-thumb {
  background-color: #643ca4;
  border-radius: 10px;
}

.AboutCdac-AboutContainer {
  margin-top: 20px;
}

.AboutCdac-Paragraph {
  font-family: var(--primary-font);
  font-size: 18px;
  text-align: justify;
  margin-bottom: 20px; /* Added margin bottom for paragraph spacing */
}

.AboutProject-AboutContainer {
  margin-top: 20px;
}

.AboutProject-Paragraph {
  font-family: var(--primary-font);
  font-size: 18px;
  text-align: justify;
  margin-bottom: 20px; /* Added margin bottom for paragraph spacing */
}

.AboutProject-ButtonContainer {
  margin-top: 20px;
}

.nav-link {
  color: var(--primary-color);
}
.nav-link:hover {
  color: var(--primary-color);
}

@media screen and (max-width: 750px) {
  .flex-row1 {
    display: flex;
    flex-direction: column;
  }

  .about-content-container {
    width: 80%;
  }
}
