.LogoBar-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
}

.LogoBar-topDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 4px;
  background-color: var(--white-color);
  color: var(--black-color);
  font-family: var(--primary-font);
  box-shadow: var(--elevation-primary);
}

.LogoBar-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.LogoBar-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
}

.LogoBar-titleSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.LogoBar-title {
  margin: 0;
}

.LogoBar-image1 {
  width: 160px;
}
.LogoBar-image2 {
  width: 55px;
}
.LogoBar-image3 {
  width: 45px;
}
.LogoBar-image4 {
  width: 90px;
}

.LogoBar-image[data-content="true"] {
  object-fit: contain;
}

.LogoBar-image[data-contain="false"] {
  object-fit: cover;
}
