.research-card {
  box-shadow: var(--elevation-primary);
  transition: transform 0.3s;
}

.research-card:hover {
  transform: scale(1.001);
  box-shadow: var(--elevation-ter);
}

.ResearchCard-card {
  border-radius: 5px;
  background: white;
  padding: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.ResearchCard-title {
  font-family: Inter;
  font-size: 22px;
  text-decoration: underline;
}

.ResearchCard-authors {
  font-size: 14px;
  color: gray;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
}

.ResearchCard-description {
  text-align: justify;
  margin-top: 5px;
}

.ResearchCard-styledDiv {
  margin-top: 10px;
}
