.Projects-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: var(--primary-font);
}

.Projects-content {
  width: 80%;
  margin-top: 50px;
  margin-bottom: 200px;
}

.Projects-description {
  text-align: justify;
}

.Projects-unordered-list {
  list-style-type: disc;
  margin-left: 20px;
}

.Projects-investigator-details {
  margin-top: 20px;
}
