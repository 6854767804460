.Modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.Modal-content {
  background: white;
  width: 50%;
  margin: auto;
  padding: 2%;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.Modal-closeButtonContainer {
  z-index: 100;
  display: flex;
  justify-content: flex-end;
}

.Modal-icon {
  cursor: pointer;
}

@media screen and (max-width: 760px) {
  .Modal-content {
    width: 90%;
  }
}
