.Loader-LoaderContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 10px;
  border-radius: 10px;
  color: #b76b13;
  gap: 10px;
}

.Loader-StyledCircularProgress {
  color: #b76b13;
}

.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  display: none;
}

.custom-backdrop.show {
  display: block;
}
