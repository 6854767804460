.AccessbilityBar-styled-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
}

.AccessbilityBar-styled-top-div {
  display: flex;
  justify-content: center;
  padding: 8px;
  background-color: var(--white-color);
  color: var(--black-color);
  font-family: var(--primary-font);
}

.AccessbilityBar-content-div {
  display: flex;
  align-items: center;
}

.AccessbilityBar-skip-link {
  padding-right: 10px;
  cursor: pointer;
  margin-left: 5px;
}
