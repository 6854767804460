.News-Container {
  display: flex;
  justify-content: center;
}

.News-ContentWrapper {
  width: 80%;
  padding: 20px;
}

.News-NewsListWrapper {
  margin-top: 30px;
}

.NewsCard-CardWrapper {
  cursor: pointer;
  transition: transform 0.2s;
  transform: scale(1);
}

.NewsCard-CardWrapper:hover {
  transform: scale(1.01);
}

.NewsCard-CardContent {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.NewsCard-DateText {
  color: gray;
}

.NewsCard-HeadingText {
  margin: 0;
}

.NewsCard-HeadlineText {
  margin-top: 10px;
}

.NewsCard-ImageWrapper {
  display: flex;
}

.NewsCard-Image {
  border-radius: 10px;
  width: 400px;
}
