input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.recaptcha-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  transform: scale(0.8);
  transform-origin: 0 0;
  margin-top: 10px;
}
