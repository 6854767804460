.NewsDetails-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.NewsDetails-message {
  font-family: var(--primary-font);
}
