.SimpleCard-StyledCard {
  background: #f5f5f5;
  box-shadow: var(--elevation-secondary);
  border-radius: var(--md-radius);
  cursor: pointer;
  padding: 10px;
}

.SimpleCard-CardText {
  display: flex;
  justify-content: center;
}

.SimpleCard-CardText span {
  font-family: var(--header-font);
  margin-bottom: 10px;
}

.SimpleCard-CardImage {
  width: 100%;
  height: 150px;
}
