.Activities-activities-container {
  background-color: var(--secondary-color);
  padding: 50px;
  margin-top: 10px;
}

.Activities-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.Activities-clickable-div {
  cursor: pointer;
}
