.ActivitiesComponent-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--primary-font);
}

.ActivitiesComponent-Content {
  width: 80%;
  padding: 20px;
}

.ActivitiesComponent-Date {
  color: gray;
}

.ActivitiesComponent-Image {
  border: 0.3rem solid black;
  width: 100%;
}

.ActivitiesComponent-Paragraph {
  font-size: 18px;
  text-align: justify;
  margin-top: 15px;
}

.ActivitiesComponent-Divider {
  background: black;
  width: 75%;
  height: 1px;
}

.ActivitiesComponent-ImageRow {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.imagerow-image {
  width: 45%;
  min-width: 250px;
}

.ActivitiesComponent-IframeRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
}

.ActivityCardComponent-Card {
  background: white;
  width: 350px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.ActivityCardComponent-Card:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.ActivityCardComponent-Image {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
}

.ActivityCardComponent-Content {
  padding: 10px;
}

.ActivityCardComponent-Date {
  font-size: 12px;
  color: gray;
  font-weight: 500;
  margin-bottom: 4px;
}

.ActivityCardComponent-Title {
  margin-bottom: 4px;
}

.ActivityCardComponent-Description {
  text-align: justify;
}

.ActivityCardsComponent-CardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  padding: 20px;
}
