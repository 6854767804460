.FrontSection-container {
  display: flex;
  width: 100%;
  height: 80%;
  align-items: center;
}

.FrontSection-top-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}

.FrontSection-heading1 {
  color: var(--teal-color);
  margin-bottom: 20px;
  text-align: center;
  font-family: var(--header-font);
  font-size: 2.5rem;
}

.FrontSection-heading3 {
  text-align: center;
  width: 100%;
  color: var(--primary-color);
  font-family: var(--primary-font);
  font-size: 1.2rem;
}

.FrontSection-para {
  text-align: center;
  font-family: var(--primary-font);
  color: var(--primary-color);
  font-size: 14px;
  margin-top: 1rem;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .FrontSection-top-container {
    width: 90%;
  }
}
