.PrivacyPolicy-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-family: var(--primary-font);
}

.PrivacyPolicy-content {
  width: 50%;
  text-align: justify;
  font-size: 18px;
  margin-bottom: 100px;
}
