.Mnp-container {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  font-family: var(--primary-font);
}

.Mnp-content-wrapper {
  display: flex;
  width: 80%;
  margin-top: 50px;
  flex-direction: column;
}

.Mnp-text {
  text-align: justify;
}

.Mnp-list {
  margin-left: 20px;
}
