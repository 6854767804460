.ExploreData-container {
  display: flex;
  justify-content: center;
  margin: 50px;
}

.ExploreData-filter-wrapper {
  width: 100%;
  flex-direction: column;
}

.ExploreData-content-wrapper {
  display: flex;
  justify-content: center;
}

.ExploreData-main-content {
  width: 80%;
}

.ExploreData-chart-and-carousel-wrapper {
  margin-bottom: 70px;
}

.ExploreData-chart-wrapper1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.ExploreData-chart-wrapper {
  background: var(--white-color);
  box-shadow: var(--elevation-secondary);
  padding: 20px;
  border-radius: var(--lg-radius);
  width: 65%;
}

.ExploreData-chart-radius {
  width: 30%;
  min-width: 250px;
  height: auto;
  border-radius: var(--lg-radius);
}

.ExploreData-search-bar-wrapper {
  display: flex;
  justify-content: center;
  margin: 25px;
}

.ExploreData-styled-width {
  width: 50%;
  min-width: 350px;
}

.ExploreData-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 800px) {
  .ExploreData-chart-wrapper {
    width: 100%;
  }

  .ExploreData-chart-radius {
    display: none;
  }
}
