.ChangePasswordForm-FormContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ChangePasswordForm-CarouselWrapper {
  width: 42%;
  min-width: 200px;
}

.ChangePasswordForm-FormContent {
  display: flex;
  /* width: 48%; */
  flex-direction: column;
  z-index: 100;
  background: white;
}

.ChangePasswordForm-FormTitle {
  color: black;
}

.ChangePasswordForm-Label {
  color: black;
}

.ChangePasswordForm-Input {
  margin-bottom: 20px;
  padding: 10px;
}

.ChangePasswordForm-BackLink {
  color: var(--primary-color);
  margin-top: 20px;
  cursor: pointer;
}

.GetEmailForm-FormWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.GetEmailForm-CarouselWrapper {
  width: 42%;
  min-width: 200px;
}

.re-captcha-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  transform: scale(0.8);
  transform-origin: 0 0;
  margin-top: 10px;
}

.GetEmailForm-FormContainer {
  display: flex;
  flex-wrap: wrap;
  /* width: 48%; */
  flex-direction: column;
  z-index: 100;
  background: white;
}

.GetEmailForm-Title {
  color: black;
}

.GetEmailForm-Label {
  color: black;
}

.GetEmailForm-Input {
  margin-bottom: 20px;
  padding: 10px;
}

.GetEmailForm-BackLink {
  color: var(--primary-color);
  margin-top: 20px;
  cursor: pointer;
}

.GetEmailForm-FlexContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.GetOTPForm-FormWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.GetOTPForm-CarouselWrapper {
  width: 42%;
  min-width: 200px;
}

.GetOTPForm-FormContainer {
  display: flex;
  /* width: 48%; */
  flex-direction: column;
  z-index: 100;
  background: white;
}

.GetOTPForm-Title {
  color: black;
}

.GetOTPForm-BackLink {
  color: var(--primary-color);
  margin-top: 20px;
  cursor: pointer;
}

/* .GetOTPForm-OtpInputStyled input {
  padding: 10px;
} */

.GetOTPForm-FlexContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

/* OTPInput.css */
.otp-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* justify-content: center; */
  /* margin: 20px 0; */
}

.otp-field {
  width: 40px;
  height: 40px;
  /* margin: 0 5px; */
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.otp-field:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px var(--primary-color);
  outline: none;
}

@media (max-width: 600px) {
  .otp-field {
    width: 30px;
    height: 30px;
    font-size: 18px;
    margin: 0 3px;
  }
}
