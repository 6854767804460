.ActivityCards-styled-div {
  margin: 30px;
  width: 260px;
  cursor: pointer;
  transition: transform 0.5s;
  border-radius: var(--lg-radius);
}

.ActivityCards-styled-div:hover {
  transform: scale(1.1);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ActivityCards-image {
  width: 100%;
  border-radius: var(--lg-radius);
}

.ActivityCards-content-div {
  padding-bottom: 8px;
}

.ActivityCards-heading {
  font-family: var(--header-font);
  text-align: center;
}

.ActivityCards-subheading {
  font-family: var(--primary-font);
  color: var(--teal-color);
  text-align: center;
}
