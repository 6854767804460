.FeedbackModal-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.FeedbackModal-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.FeedbackModal-label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.FeedbackModal-checkbox {
  margin-right: 10px;
}

.FeedbackModal-text-input {
  /* margin-left: 10px; */
}

.FeedbackModal-textarea {
  height: 100px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.FeedbackModal-button-container {
  margin-top: 20px;
}

.FeedbackModal-subjects-label {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
