.Header-top-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--primary-color);
  color: var(--white-color);
  font-family: var(--primary-font);
}

/* .Header-hr {
  width: 50%;
  margin: 0 auto;
  border: none;
  border-top: 1px solid white;
  margin-bottom: 20px;
} */

.Header-social-link {
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  gap: 10px;
}

.Header-social-link:hover {
  text-decoration: underline;
}

.Header-social-icon {
  margin-right: 10px;
}

.Header-footer-side-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 15px;
}

.Header-footer-contact {
  display: flex;
  flex-direction: column;
  color: white;
}

.Header-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Header-nav-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.Header-nav-item {
  margin-right: 20px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.Header-nav-item:hover {
  text-decoration: underline;
}

.Header-divider {
  display: none;
}

/* .Header-social-links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  width: 25%;
} */

.Header-social-links {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .Header-top-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Header-social-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .Header-div {
    width: 100%;
  }

  .Header-footer-side-container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .Header-footer-contact {
    flex-direction: row;
  }

  .Header-nav-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .Header-nav-item {
    margin-right: 30px;
  }

  .Header-divider {
    display: block;
    width: 1px;
    height: 30px;
    background-color: white;
    margin-right: 20px;
  }
}

.Header-div-com {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: white;
}

.Header-footer-text {
  margin-right: 20px;
}
