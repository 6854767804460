.SearchBarLg-container {
  background: var(--white-color);
  box-shadow: var(--elevation-secondary);
  border-radius: var(--md-radius);
  display: flex;
  align-items: center;
  padding: var(--pad-md);
  font-family: var(--primary-font);
}

.SearchBarLg-input {
  background: var(--white-color);
  font-size: var(--primary-text);
  border: none;
  width: 100%;
  padding: var(--pad-md);
  outline: none;
}

.SearchBarLg-icon {
  margin-left: var(--mar-lg);
}
