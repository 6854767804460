.Button-styled-button {
  background: var(--primary-color);
  color: var(--white-color);
  padding: 5px 20px;
  border-radius: var(--md-radius);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Button-styled-button:hover {
  background-color: var(--primary-hover);
}

.Button-nav-bar-button {
  background: var(--white-color);
  color: var(--primary-color);
  padding: 7px 35px;
  letter-spacing: 1px;
  border-radius: var(--md-radius);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Button-nav-bar-button:hover {
  background-color: var(--secondary-color);
}

.Button-text-style {
  font-weight: var(--regular);
  font-size: var(--primary-text);
}
