.channelList-container {
  display: flex;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.channelList-styled-span {
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 5px 15px;
  color: var(--primary-color);
  background: transparent;
  cursor: pointer;
  margin: 2px;
}

.channelList-styled-span.selected {
  color: var(--white-color);
  background: var(--primary-color);
}

.channelList-styled-span:hover {
  color: var(--white-color);
  background: var(--primary-color);
}
