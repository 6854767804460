.Card-styled-div {
  margin: 30px;
  width: 250px;
  height: 380px;
  cursor: pointer;
  transition: transform 0.5s;
}

.Card-styled-div:hover {
  transform: scale(1.1);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Card-image {
  width: 100%;
}

.Card-content-div {
  padding: 20px;
}

.Card-heading {
  font-family: var(--header-font);
  font-weight: 600;
  text-align: center;
}

.Card-sub-heading {
  font-family: var(--primary-font);
  color: var(--teal-color);
  text-align: center;
}
