.Services-container {
  background-color: var(--secondary-color);
  padding: 50px;
  margin-top: 10px;
}

.Card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.Clickable-card {
  cursor: pointer;
}
