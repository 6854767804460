.LandingPage-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  background-color: var(--white-color);
}

.LandingPage-content-container {
  display: flex;
  width: 80%;
  justify-content: flex-end;
  flex-direction: column;
}

.LandingPage-styled-div {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  justify-content: space-between;
  /* justify-content: center; */
  /* gap: 30px; */
  align-items: center;
  height: 100%;
  border-radius: 10px;
  padding: 40px;
  box-shadow: var(--elevation-secondary);

  @media (max-width: 1056px) {
    justify-content: center;
  }
}

@media screen and (max-width: 750px) {
  .LandingPage-styled-div {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    /* justify-content: space-between; */
    justify-content: center;
    gap: 30px;
    align-items: center;
    height: 100%;
    border-radius: 10px;
    padding: 40px;
    box-shadow: var(--elevation-secondary);
  }
}

.LandingPage-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  /* text-align-last: left; */
  align-items: center;
  font-family: var(--primary-font);
  font-size: clamp(14px, 18px, 18px);
}

.LandingPage-heading {
  text-align: center;
  font-family: var(--primary-font);
}
