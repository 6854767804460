.FilterModal-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.FilterModal-modal-title {
  font-size: var(--H2);
  font-family: var(--header-font);
  font-weight: var(--bold);
  margin-bottom: 20px;
}

.FilterModal-filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.FilterModal-span {
  background-color: var(--primary-color);
  border-radius: 25px;
  color: var(--white-color);
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 10px;
}
