.LoginModal-container {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  gap: 10px;
  /* justify-content: space-evenly; */
  align-items: center;
  width: 100%;
  height: 100%;
}

.LoginModal-leftSide {
  width: 49%;
}

.LoginModal-rightSide {
  display: flex;
  flex-direction: column;
  width: 49%;
  z-index: 100;
  background: white;
}

.LoginModal-title {
  color: black;
}

.LoginModal-label {
  color: black;
  margin-bottom: 5px;
}

.LoginModal-input {
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid black;
  outline: none;
  width: 100%;
}

.LoginModal-passwordContainer {
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
  display: flex;
  border: 1px solid black;
  align-items: center;
}

.LoginModal-passwordInput {
  flex: 1;
  border: none;
  outline: none;
}

.LoginModal-eyeOpen,
.LoginModal-eyeClose {
  cursor: pointer;
}

.LoginModal-forgetPasswordWrapper {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.LoginModal-forgotPassword {
  color: var(--primary-color);
  font-size: var(--small-text);
  cursor: pointer;
}

.LoginModal-signUp {
  color: var(--primary-color);
  margin-top: 20px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}

@media screen and (max-width: 1045px) {
  .LoginModal-container {
    flex-direction: column;
  }

  .LoginModal-leftSide {
    width: 100%;
  }

  .LoginModal-rightSide {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 100;
    background: white;
  }
}
