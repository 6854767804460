.DataDetails-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.DataDetails-content-wrapper {
  display: flex;
  width: 80%;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
}

.DataDetails-description {
  font-family: var(--primary-font);
  margin-bottom: 50px;
  text-align: justify;
}

.DataDetails-chart-container {
  display: flex;
  justify-content: center;
}

.DataDetails-chart-legend {
  display: flex;
  justify-content: center;
  font-family: var(--primary-font);
}

.DataDetails-legend-item {
  display: flex;
  align-items: center;
  margin: 10px;
}

.DataDetails-legend-text {
  margin: 5px;
}

.DataDetails-legend-text-inner {
  font-weight: 500;
}
