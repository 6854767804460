.rnd {
  display: flex;
  justify-content: center;
}

.rnd-container {
  width: 80%;
  padding: 20px;
}

.Research-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
}

.Research-StyledHeading {
  font-family: var(--primary-font);
}
