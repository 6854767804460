.Bmi-styled-container {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  font-family: var(--primary-font);
}

.Bmi-styled-inner-container {
  display: flex;
  width: 80%;
  margin-top: 50px;
  flex-direction: column;
}

.Bmi-justified-text {
  text-align: justify;
}
