.PublishDataset-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.PublishDataset-content {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.PublishDataset-description {
  font-family: var(--primary-font);
  margin-bottom: 50px;
  text-align: justify;
}

.PublishDataset-textarea {
  height: 200px;
  padding: 16px;
  background-color: white;
}

.PublishDataset-checkbox-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 20px;
}

.PublishDataset-checkbox-container {
  display: flex;
  justify-content: space-evenly;
}

.PublishDataset-checkbox-label {
  display: flex;
  align-items: center;
}

.PublishDataset-checkbox-input {
  margin-right: 10px;
}

.PublishDataset-button-container {
  margin-top: 20px;
}
