.DataConsultancy-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.DataConsultancy-form-wrapper {
  display: flex;
  width: 80%;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.DataConsultancy-styled-span {
  font-family: var(--primary-font);
  margin-bottom: 50px;
  text-align: justify;
}

.DataConsultancy-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
}

.DataConsultancy-input {
  padding: 10px;
  margin-bottom: 10px;
}

.DataConsultancy-textarea {
  padding: 20px;
}

.DataConsultancy-button-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}
