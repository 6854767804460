@font-face {
  font-family: Inter;
  src: url(./fonts/Inter/Inter.ttf);
}

@font-face {
  font-family: Imbue;
  src: url(./fonts/Imbue/Imbue.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(./fonts/Montserrat/mon.ttf);
}

:root {
  /* Font Family */
  --header-font: "Montserrat";
  --primary-font: "Inter";
  --secondary-font: "imbue";

  /* Font Size */
  --H1: 32px;
  --H2: 24px;
  --H3: 22px;
  --primary-text: 18px;
  --secondary-text: 16px;
  --small-text: 12px;

  /* Font Weight */
  --bold: 700;
  --medium: 500;
  --regular: 400;
  --light: 200;

  /* COLORS */
  --primary-color: #b86b12;
  --primary-hover: #d57b13;
  --secondary-color: #d4d9dc;
  --extra-color: #e4e5e7;
  --white-color: #ffffff;
  --black-color: #0f0f0f;
  --teal-color: #234141;

  /* Border Radius */
  --sm-radius: 2px;
  --md-radius: 5px;
  --lg-radius: 10px;

  /* Box Shadow */
  --elevation-primary: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --elevation-secondary: #b86b12 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  --elevation-ter: #b86b12 0px 7px 29px 0px;
  --elevation-paper: rgba(0, 0, 0, 0.12) 0px 1px 3px,
    rgba(0, 0, 0, 0.24) 0px 1px 2px;
  --elevation-3d: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

  /* Padding and Margin */
  --pad-sm: 2px;
  --pad-md: 5px;
  --pad-lg: 10px;
  --mar-sm: 2px;
  --mar-md: 5px;
  --mar-lg: 10px;

  /* Fixed Style */
  background-color: var(--secondary-color);
}

body {
  /* Fixed Margin */
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--white-color);
}
