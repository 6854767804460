.SignupModal-Form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.SignupModal-FormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 100;
  background: white;
}

.SignupModal-Title {
  color: black;
}

.SignupModal-Label {
  color: black;
}

.SignupModal-Input {
  padding: 5px;
}

.SignupModal-ErrorMessage {
  color: red;
  margin-bottom: 20px;
}

.SignupModal-SwitchToLogin {
  color: var(--primary-color);
  margin-top: 20px;
  cursor: pointer;
}
