.ContactUs-contact-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.ContactUs-inner-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--secondary-color);
  padding: 30px;
  width: 100%;
}

.ContactUs-inner-container-2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  background-color: var(--white-color);
  padding: 30px;
  width: 100%;
}

.ContactUs-div-container {
  width: 40%;
}

.ContactUs-title {
  font-family: var(--header-font);
}

.ContactUs-button-container {
  margin-top: 10px;
}

.ContactUs-width-div {
  width: 40%;
}

.ContactUs-image {
  /* width: 100%; */
  height: auto;
}
.ContactUs-image1 {
  height: auto;
}

@media screen and (max-width: 780px) {
  .ContactUs-image {
    width: 100%;
    height: auto;
  }

  .ContactUs-width-div {
    width: 90%;
  }

  .ContactUs-div-container {
    width: 90%;
  }
}
