.Input-container {
  font-family: var(--primary-font);
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.Input-label {
  margin-bottom: 4px;
}

.Input-textarea {
  padding: 8px;
  margin-top: 2px;
  font-family: var(--primary-font);
  font-size: var(--small-text);
  height: 120px; /* Adjust height as needed */
}

.Input-input {
  padding: 5px;
  margin-top: 2px;
  font-family: var(--primary-font);
  font-size: var(--small-text);
  width: 100%; /* Adjust width as needed */
}
