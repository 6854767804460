.NavBar-styled-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 95%;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.NavBar-styled-top-div {
  display: flex;
  justify-content: center;
  padding: 15px; /* Example padding value, adjust as needed */
  background-color: var(--primary-color);
  color: var(--white-color);
  font-family: var(--primary-font);
}

.NavBar-nav-links {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
}

.NavBar-nav-link {
  cursor: pointer;
}
