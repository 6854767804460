/* .FilterData-container {
  font-family: var(--primary-font);
}

.FilterData-filter-container {
  border: 0.5px solid var(--primary-color);
  align-items: center;
  border-radius: 20px;
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  background-color: var(--white-color);
  box-shadow: var(--elevation-primary);
}

.FilterData-filter-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.FilterData-divider {
  width: 1px;
  height: 50px;
  background-color: var(--primary-color);
}

.FilterData-reset-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.FilterData-span {
  margin-right: 0;
  margin-left: 0;
}

.FilterData-span.FilterData-marginRight {
  margin-right: 10px;
}

.FilterData-span.FilterData-marginLeft {
  margin-left: 10px;
} */

.FilterData-Top-Container {
  display: flex;
  justify-content: center;
}

.FilterData-container {
  border-radius: 20px;
  background-color: var(--white-color);
  box-shadow: var(--elevation-primary);
  border: 0.5px solid var(--primary-color);
  display: flex;
  width: 40%;
  min-width: 300px;
}

.FilterData-item {
  border: 0.5px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px 0px 0px 20px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}

.FilterData-item1 {
  border: 0.5px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 0px 20px 20px 0px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}
