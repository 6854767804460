.Heading-box {
  height: 32px;
  width: 3px;
  margin-right: 5px;
  background-color: var(--primary-color);
}

.Heading-text {
  color: var(--primary-color);
  font-size: var(--H3);
  font-family: var(--header-font);
  font-weight: var(--medium);
}

.Heading-div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
