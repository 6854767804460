.CustomizeOrder-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.CustomizeOrder-form-wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.CustomizeOrder-description-text {
  font-family: var(--primary-font);
  margin-bottom: 50px;
  text-align: justify;
}

.CustomizeOrder-textarea {
  height: 200px;
  padding: 16px;
  background-color: white;
}

.CustomizeOrder-modalities-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 20px;
}

.CustomizeOrder-modalities-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.CustomizeOrder-checkbox-label {
  margin: 10px;
}

.CustomizeOrder-subject-count-label {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.CustomizeOrder-request-button-wrapper {
  margin-top: 20px;
}
